<template>
  <div class="carrier-upload page-container">
    <PageTitle title="雲端載具" />
    <p class="carrier-upload_content mb-6">
      可登錄之載具發票照片類型：<br />
      雲端載具、便利商店APP會員、信用卡載具等
    </p>
    <!-- input -->
    <v-form ref="form" v-model="valid" class="invoice_form">
      <v-text-field
        v-model="number"
        placeholder="發票號碼 ex: AB12345678"
        type="text"
        solo
        required
        :rules="numberRules"
        class="invoice_input"
      ></v-text-field>
      <date-picker
        class="invoice_picker"
        v-model="date"
        valueType="format"
        :editable="false"
        :clearable="false"
        :disabled-date="disabledDate"
        placeholder="請選擇發票日期"
      >
        <!-- <i slot="icon-calendar">1</i> -->
      </date-picker>
      <!-- Photo Upload -->
      <div class="photo_upload_item mt-8">
        <label for="img">
          <div class="photo_upload_btn">請點此上傳或拍攝發票照片</div>
        </label>
        <input
          type="file"
          name="img"
          id="img"
          hidden
          accept="image/png, image/jpeg"
          @change="uploadPreiview($event)"
        />
        <div class="photo_upload_box">
          <img v-if="url === ''" src="@/assets/img/uploaddemo.jpg" alt="">
          <img v-if="url !== ''" :src="url" />
        </div>
      </div>
    </v-form>
    <BaseBtn text="送出登錄資料" :disabled="!isValid" @click="submit" />
    <BaseBtn
      text="重新選擇登錄發票類型"
      type="back"
      :isOutlined="true"
      color="primary"
      @click="routeTo('/InvoiceLog')"
    />
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import BaseBtn from "@/components/BaseBtn.vue";
import form from "@/mixins/form.vue";

export default {
  name: "CarrierUpload",
  mixins: [form],
  components: {
    PageTitle,
    BaseBtn,
  },
  data() {
    return {
      valid: false,
      number: "",
      date: "",
      url: "",
      img: "",
    };
  },
  computed: {
    isValid() {
      return this.valid && this.date && this.url;
    },
  },
  methods: {
    routeTo(route) {
      this.$router.push(route);
    },
    async uploadPreiview(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.url = e.target.result;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    async submit() {
      this.$store.commit("updateLoading", true);
      let url = process.env.VUE_APP_APIUrl + "api/v1/users/get-user-token";
      let data = {
        lineToken: window.localStorage.getItem("userToken"),
      };
      await this.axios
        .post(url, data)
        .then((res) => {
          window.localStorage.setItem("jwt", res.data.data.token);
          let url =
            process.env.VUE_APP_APIUrl + "api/v1/auth/invoices/cloud-invoices";
          let data = {
            invoiceNumber: this.number.toUpperCase(),
            invoiceDate: this.date,
            image: this.url,
          };
          this.axios
            .post(url, data, {
              headers: {
                Authorization: "Bearer " + window.localStorage.getItem("jwt"),
              },
            })
            .then((res) => {
              console.log(res);
              this.$store.commit("updateLoading", false);
              this.$router.push({ path: "result", query: { result: "1" } });
            })
            .catch((e) => {
              console.log(e);
              this.$store.commit("updateLoading", false);
              this.$router.push({ path: "result", query: { result: "5" } });
            });
        })
        .catch((e) => {
          console.log(e);
          alert("系統異常");
          this.$store.commit("updateLoading", false);
        });
    },
  },
};
</script>
